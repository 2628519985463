.medium-width {
    max-width: 800px !important;
}

@media screen and (max-width: 798px) {
    .medium-width {
        max-width: none !important;
        width: 95% !important;
    }
}

.mod-confirmation .primary-title {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.mod-confirmation img {
    max-width: 400px;
    width: 80%;
    height: 200px;
}

.paymentForm .MuiButton-containedPrimary {
    background-color: #5C9F3D !important;
}

.paymentForm .MuiButton-containedPrimary:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    border: 1px solid #5C9F3D !important;
}

.paymentForm-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymentForm-card {
    width: 90px;
    height: 55px;
}

.paymentForm-container {
    min-height: calc(100vh - 186px);
}

.paymentConfirmation-container {
    min-height: calc(100vh - 186px);
}

@media screen and (max-width: 960px) {
    .paymentForm-container {
        height: auto;
    }

    .paymentConfirmation-container {
        height: auto;
    }
}

@media screen and (max-width: 414px) {
    .paymentForm-container {
        height: auto;
    }

    .paymentConfirmation-container {
        height: auto;
    }
}

.paymentForm-form {
    height: 100%;
    position: relative;
}

.paymentForm-loading {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    bottom: 5px;
}

@media screen and (max-width: 414px) {
    .paymentForm-loading {
        display: flex;
        align-items: center;
        position: absolute;
        right: 10px;
        bottom: 5px;
    }
}

.paymentForm-text {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-size: 10px;
    color: #000;
    font-weight: bold;
}

.paymentForm-colecturia {
    width: 100px;
    height: 50px;
    margin-left: 10px;
}