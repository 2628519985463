/****************************/

.main-footer {
    background-color: #27292D;
    font-size: 13px;
    color: #FFF;
    width: 100%;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    grid-row: 3/3;
    height: 81px;
}

@media screen and (max-width: 414px) {
    .main-footer {
        background-color: #27292D;
        font-size: 13px;
        color: #FFF;
        width: 100%;
        bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        grid-row: 3/3;
        height: auto;
    }
}

.main-footer p {
    padding: 0;
    margin: 0;
}

.list,
.rrss {
    margin: 0;
    padding: 0;
}

.list li {
    display: inline-block;
    vertical-align: top;
}

.list li:after {
    content: '\007C';
    padding: 0 0.5em;
}

.list li:last-child:after,
.rrss li:after {
    display: none;
}

.rrss li {
    padding-right: 0.5em;
}

.icon-footer {
    color: #70BF4D;
    font-size: 16px !important;
    vertical-align: middle;
}

.icon-footer.fa-home {
    padding-right: .25em;
}

.main-footer .list li a {
    color: #5C9F3D;
    text-decoration: none;
}

.main-footer .list li a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 750px) {
    .main-footer {
        position: relative;
        /* margin-top: 6em; */
    }
}

.footer__logo {
    display: flex;
    height: 50px;
    width: 150px;
}

.grecaptcha-badge {
    bottom: 84px !important;
}

@media screen and (max-width: 414px) {
    .grecaptcha-badge {
        bottom: 14px !important;
    }
}