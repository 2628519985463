.headerContainer {
    vertical-align: top;
    width: 100%;
    margin-top: 20px;
}

.alignleft {
    float: left;
    width: 100%;
    text-align: left;
}

.aligncenter {
    float: left;
    width: 60%;
    margin-top: 30px;
    text-align: center;
}

.alignright {
    float: left;
    width: 20%;
    text-align: right;
}


/****************************/

.main-header {
    top: 0;
    background: #FFF;
    width: 100%;
    z-index: 4;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-shadow: 0px -1px 15px 2px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px -1px 15px 2px rgba(0, 0, 0, 0.18);
    box-shadow: 0px -1px 15px 2px rgba(0, 0, 0, 0.18);
}

.col-flex {
    flex: 1;
}

@media screen and (max-width: 414px) {
    .col-flex {
        display: flex;
    }
}

.col-auto {
    padding: 1em;
}

.header__module--txt.pr-1 {
    padding-right: 1em;
}

.main-header .col-auto.mr-1 {
    margin-right: 2em;
}

.main-header__title {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 22px;
    color: #000;
    padding-top: 15px;
}

.main-header__logo {
    margin-right: 10px;
    margin-left: 10px;
    width: 130px;
    display: inline-block;
    vertical-align: middle;
}

.header__module {
    font-size: 13px;
    display: flex;
}

.header__module p {
    padding: 0;
    margin: 0;
}

.header__module--icon {
    flex: 0 0 0;
    vertical-align: middle;
    border-right: 1px solid #DDD;
    padding-right: 1em;
    color: #70BF4D;
    font-size: 18px;
}

.header__module--txt {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: right;
}

.header__module li {
    padding-right: 1em;
    font-weight: 700;
    padding-bottom: 0.5em;
    text-align: right;
}

.header__module li a {
    color: #5C9F3D;
    text-decoration: none;
}

.header__module li a:hover {
    color: #000;
}


/* button */

button {
    cursor: pointer;
}

.breadcrumb-link {
    cursor: pointer;
}

/* primary */

.btn-secondary.MuiButton-contained {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    background-color: #FFF;
    border: 1px solid #5C9F3D;
    color: #5C9F3D;
    border-radius: 4px;
    font-size: 13px;
    box-shadow: none;
    text-transform: capitalize;
}

.main-header .btn-secondary span {
    vertical-align: middle;
}

.btn-secondary.MuiButton-contained:hover {
    background-color: #5C9F3D;
    color: #FFF;
    box-shadow: none;
}

@media screen and (max-width: 1070px) {
    /* utilities header */
    .main-header .col-auto.mr-1 {
        margin-right: 0.2em;
    }
}

@media screen and (max-width: 990px) {
    .main-header__title {
        font-size: 16px;
    }
    .main-header__logo {
        width: 90px;
    }
}

@media screen and (max-width: 840px) {
    .main-header .col-auto:nth-child(2) {
        order: 4;
        background-color: #F1F1F1;
        width: 100%;
        border-bottom: 1px solid #FFF;
    }
    .header__module p {
        display: inline-block;
        padding-right: 0.7em;
    }
    .main-header .col-auto:nth-child(2) .header__module--icon {
        display: none;
    }
    .main-header .col-auto:nth-child(2) .header__module--txt {
        text-align: left;
    }
}

@media screen and (max-width: 660px) {
    .clear-txt-responsive {
        display: none;
    }
    .main-header .btn-secondary i {
        padding-right: 0;
    }
}

@media screen and (max-width: 560px) {
    .main-header__logo {
        float: left;
    }
}

.main-breadcrumb {
    padding: 0.5em 1em;
    background-color: #70BF4D;
    font-family: Montserrat, Arial, Helvetica, sans-serif !important;
    -webkit-box-shadow: inset 0px 10px 9px -7px rgba(0, 0, 0, 0.18);
    box-shadow: inset 0px 10px 9px -7px rgba(0, 0, 0, 0.18);
}

.main-breadcrumb .MuiBreadcrumbs-ol {
    font-size: 13px;
    color: #FFF;
    font-weight: 600;
}

.main-breadcrumb .MuiBreadcrumbs-ol a {
    color: #FFF;
}

.main-breadcrumb svg {
    vertical-align: middle;
    margin-right: 5px;
}