.modal-body__fail {
    font-size: 13px;
    text-align: center;
    position: relative;
}

.modal-body__fail h2 {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 20px;
    color: #CF4646;
}

.modal-body__fail .MuiButton-containedPrimary {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    background-color: #CF4646;
    border: 1px solid #CF4646;
    box-shadow: none;
    font-size: 15px;
    font-weight: 700;
    padding: 0.6em 2em;
    line-height: 1em;
}

.modal-body__fail .MuiButton-containedPrimary:hover {
    background-color: #FFF;
    color: #CF4646;
    box-shadow: none;
}

.modal-link {
    color: blue;
}

.modal-decription {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #CF4646;
}

.paymentModal-colecturia {
    width: 100px;
    height: 50px;
    position: absolute;
    bottom: 0;
    right: 0;
}