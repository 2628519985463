.btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.noInternet-icon {
    height: 32px !important;
    width: 32px !important;
    color: #5C9F3D;
    padding-right: 12px;
}


.noInternet-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noInternet-modal {
    padding: 16px 32px 24px;
    position: relative;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    background-color: #FFF;
    width: 400px;
}

@media screen and (max-width: 414px) {
    .noInternet-modal {
        padding: 16px 32px 24px;
        position: relative;
        box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
        background-color: #FFF;
        width: 300px;
    }
}