.customStepper-background {
    background-color: #F1F1F1;
    background-image: url('../../img/bg-tires.jpg');
    padding: 24px 0 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #5C9F3D !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #5C9F3D !important;
}

#customStepper-node-0 {
    padding-left: 0 !important;
}