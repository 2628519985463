.modal-body__terms {
    font-size: 13px;
}

.modal-scroll {
    height: 350px;
    overflow: auto;
    padding-bottom: 20px;
    padding-right: 15px;
}

.modal-body__terms h2 {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 25px;
    font-size: 20px;
    text-align: center;
}

.modal__img {
    max-width: 60%;
    width: 390px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}