/* utilities */

.description {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-size: 17px;
    color: #222;
    font-weight: 400;
}

.text-conditions {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-size: 12px;
}

.text-conditions .MuiIconButton-root {
    padding-left: 0;
    float: left;
    margin-top: -5px;
}

.link-green {
    color: #70BF4D;
    cursor: pointer;
}

/* Button*/

.user-content-wrapper .MuiButton-containedPrimary {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    background-color: #70BF4D;
    border: 1px solid #70BF4D;
    box-shadow: none;
    font-size: 15px;
    font-weight: 700;
}

.user-content-wrapper .MuiButton-containedPrimary:hover {
    background-color: #FFF;
    color: #70BF4D;
    box-shadow: none;
}

.special-padding {
    padding: 0.6em 2em !important;
}

::-webkit-calendar-picker-indicator {
    color: #5C9F3D !important;
}

/* Input */

.user-content-wrapper .MuiFormLabel-root.Mui-focused {
    color: #5C9F3D;
}

.user-content-wrapper .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #5C9F3D;
    border-width: 2px;
}

.user-content-wrapper .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(92, 159, 61, .5);
}

.col-image {
    text-align: center;
    /*margin-top: -73px !important;*/
    background: -moz-linear-gradient(-45deg, #DDD 0%, rgba(251, 251, 251, 0) 63%);
    background: -webkit-linear-gradient(-45deg, #DDD 0%, rgba(251, 251, 251, 0) 63%);
    background: linear-gradient(135deg, #DDD 0%, rgba(251, 251, 251, 0) 63%);
}

.col-image img {
    max-width: 437px;
    width: 100%;
    padding-top: 10px;
}

@media screen and (max-width: 900px) {
    .col-image {
        display: none;
    }
}

.MuiPickersToolbar-toolbar {
    background-color: #5C9F3D !important;
}

.MuiPickersDay-daySelected {
    background-color: #5C9F3D !important;
}

.center {
    display: flex;
    align-items: center;
}

.mt-0 {
    margin-top: 0 !important;
}

.userForm-stepper {
    max-width: 600px;
    margin-bottom: 0;
}

.userForm-tooltip {
    font-size: 16px !important;
    vertical-align: bottom !important;
    color: #5C9F3D !important;
}

@media screen and (max-width: 414px) {
    .userForm-stepper {
        max-width: 500px;
        margin-bottom: 1em;
    }
}

.userForm-height {
    min-height: calc(100vh - 166px);
}

@media screen and (max-width: 960px) {
    .userForm-height {
        height: auto;
    }
}

@media screen and (max-width: 414px) {
    .userForm-height {
        height: auto;
    }
}