.App {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

#root {
    position: relative;
    min-height: 100vh;
    background-color: #F1F1F1;
    background-image: url('img/bg-tires.jpg');
    background-size: cover;
}

.container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    position: relative;
}

@media screen and (max-width: 414px) {
    .container {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
        position: relative;
    }
}

.Header {
    position: relative;
    height: 100px;
    width: 100%;
}

.userForm-container {
    grid-row: 2/3;
}

.header-container {
    grid-row: 1/2;
    min-height: 85px;
}

@media screen and (max-width: 414px) {
    .header-container {
        grid-row: 1/2;
        height: auto;
    }
}

.user-content-wrapper {
    max-width: 95%;
    margin: auto !important;
    height: 100%;
}

.Main {
    text-align: center;
    position: relative;
}

.Footer {
    height: 20px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #5C9F3D;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.color-error {
    color: #f44336;
}

.show-loading {
    position: fixed !important;
    z-index: 10000;
    top: 50%;
    left: 50%;
    margin-left: -35px;
    margin-top: -35px;
}

.hide-loading {
    position: fixed !important;
    z-index: 10000;
    top: 50%;
    display: none !important;
}

.MuiCircularProgress-colorPrimary {
    color: #5C9F3D !important;
}

@media screen and (min-width: 415px) {
    .fQhxZd {
        display: none !important;
    }
    .lihaRD {
        display: none !important;
    }
    .ksvteM {
        display: none !important;
    }
    .gqSlGV {
        display: none !important;
    }
}

.expandable-card {
    padding: 5px 15px;
}

.text-sm {
    font-size: 13px;
}

.litle-gray {
    color: rgba(0, 0, 0, 0.54);
}

.text-error {
    font-size: 14px !important;
    font-weight: bold !important;
}