/* Utilities */

small {
    font-size: 12px;
}

.recharge {
    color: #FF9D9D;
}

.discount {
    color: #70BF4D;
}

.total {
    font-weight: 700;
    font-size: 30px;
}

.txt-fines p {
    margin: 0;
}

.color-green {
    color: #5C9F3D;
}

.pb-content {
    padding-bottom: 50px;
}

.pb-content--pay {
    padding-bottom: 150px;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.total-payment {
    font-size: 20px;
    font-weight: 800;
    color: #5C9F3D;
}

.bg-LightGray {
    background-color: #ECECEC;
}

a {
    color: #5C9F3D;
    text-decoration: none;
}

.mt-1 {
    margin-top: 1em;
}

.mt-2 {
    padding-top: 1.5em;
}

.mt-3 {
    margin-top: 3em !important;
}

.mb-3 {
    margin-bottom: 3em;
}

.mb-1 {
    margin-bottom: 1em !important;
    ;
}

.m-0 {
    margin: 0;
}

.mls-1 {
    padding-left: 3.5%;
}

.p-0 {
    padding: 0 !important;
}

.ptblr {
    padding: 1em;
}

.ptblr-2 {
    padding: 2em;
}

.pl-1 {
    padding-left: 1em !important;
}

@media screen and (max-width: 414px) {
    .pl-1 {
        padding-left: 0 !important;
    }
}

.primary-title {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: #000;
    font-weight: bold;
    margin-bottom: 1em;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    border: 1px solid #DDD !important;
}

.icon-table {
    margin-left: 10px;
    vertical-align: middle;
    width: 0.6em !important;
}

/* Button*/

.content-summary .MuiButton-containedPrimary, .modal-body__fineDetails .MuiButton-containedPrimary {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    background-color: #70BF4D;
    border: 1px solid #70BF4D;
    box-shadow: none;
    font-size: 15px;
    font-weight: 700;
    padding: 0.6em 2em;
    line-height: 1em;
}

.content-summary .MuiButton-containedPrimary:hover, .modal-body__fineDetails .MuiButton-containedPrimary:hover {
    background-color: #FFF;
    color: #70BF4D;
    box-shadow: none;
}

/* NUEVOS ESTILOS */

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #70BF4D !important;
}

.text-conditions .PrivateSwitchBase-root-6 {
    padding-left: 0;
}

.content-wrapper {
    max-width: 95%;
    margin: auto !important;
    min-height: 600px;
}

.only-table-no-sidebar {
    width: 95% !important;
    max-width: 1000px !important;
}

h3 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.875rem;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
    padding-left: 16px;
    padding-right: 16px;
}

.content-wrapper .MuiListItemIcon-root {
    min-width: 35px;
}

.content-wrapper .MuiButtonBase-root {
    padding-top: 0;
    padding-bottom: 0;
}

.list-sidebar {
    background-color: #FFF;
    border: 1px solid #F0F0F0;
}

.list-sidebar .MuiListItem-root {
    padding-top: 0;
    padding-bottom: 0;
}

.list-sidebar .MuiListItemText-primary {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.54);
}

.list-sidebar .MuiListItemText-secondary {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
}

.table-content .MuiDataGrid-root {
    border: 0;
}

.table-content .MuiDataGrid-root .MuiDataGrid-colCellWrapper, .table-content .MuiDataGrid-root .MuiDataGrid-row {
    background-color: #FFF;
}

.table-content .MuiDataGrid-root .MuiDataGrid-columnSeparator {
    display: none;
}

.table-content .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
    background-color: #EEFFE5;
}

.table-content .MuiCheckbox-colorPrimary.Mui-checked {
    color: #70BF4D !important;
}

.table-content .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
    background-color: rgba(238, 255, 229, 1);
}

.table-content .MuiDataGrid-root .MuiDataGrid-colCellTitle {
    color: rgba(0, 0, 0, 0.54);
    font-size: 14px;
}

.table-content .MuiDataGrid-root .MuiDataGrid-cell {
    height: 30px !important;
}

/* Change style on Material UI Direct */

.MuiMenuItem-root {
    font-size: 14px !important;
}

.MuiDataGrid-root .MuiDataGrid-colCell:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-colCell:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.content-summary {
    background-color: #27292D;
    position: fixed;
    bottom: 0;
    z-index: 8;
    width: 100%;
    left: 0;
    color: #FFF;
}

.list-inline .MuiListItemText-secondary {
    color: #FFF !important;
}

.list-inline .MuiListItem-root {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.list-inline__value-witdh .MuiListItem-root {
    display: inline-block;
    width: 25%;
    vertical-align: middle;
}

.list-inline__value-witdh .MuiListItem-root:last-child {
    width: 75%;
}

.list-inline__value-witdh .MuiListItemText-primary {
    font-size: 13px;
    color: rgba(0, 0, 0, 0.54);
}

.list-inline__value-witdh .MuiListItemText-secondary {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
}

/* primary */

.btn-simple.MuiButton-contained {
    background-color: transparent;
    color: #5C9F3D;
    border-radius: none;
    font-size: 13px;
    padding: 0;
    box-shadow: none;
    text-transform: capitalize;
}

.btn-simple.MuiButton-contained:hover {
    background-color: transparent;
    color: #222;
    box-shadow: none;
}

.MuiDataGrid-root .MuiDataGrid-row .btn-simple.MuiButton-contained.special-hover {
    display: none;
}

.MuiDataGrid-root .MuiDataGrid-row:hover .btn-simple.MuiButton-contained.special-hover {
    display: block;
}

.table-leyend {
    font-size: 12px;
    padding: 0 1em;
    margin-top: 5px;
}

.table-leyend li {
    display: inline-block;
    padding-right: 20px
}

.table-leyend li.link-law {
    text-align: right;
    font-style: italic;
}

.table-leyend .MuiSvgIcon-root {
    vertical-align: middle;
    font-size: 16px;
    padding-right: 5px;
}

/* Table FineDetails Responsive */

.simple-table .MuiTableHead-root {
    /*opacity: .57;*/
    background-color: #F1F1F1;
}

.row-gray {
    background-color: #F1F1F1;
}

.simple-table .MuiTableCell-root {
    padding: 13px;
}

.simple-table th.MuiTableCell-root.MuiTableCell-head {
    font-size: 13px;
}

.mod-search-table {
    font-family: Montserrat, Arial, Helvetica, sans-serif;
    font-size: 15px;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #FFF;
    border-bottom: 2px solid rgb(238, 238, 238);
}

@media screen and (max-width: 950px) {
    .content-wrapper .MuiGrid-grid-sm-6, .content-wrapper .MuiGrid-grid-sm-4 {
        max-width: 100%;
        flex-basis: 100%;
    }
    .sidebar-responsive .MuiGrid-grid-sm-2 {
        max-width: 100%;
        flex-basis: 100%;
        order: 2;
    }
    .list-sidebar .MuiListItem-root {
        width: auto;
        display: inline-block;
    }
    .content-summary .MuiGrid-grid-sm-3 {
        max-width: 90%;
        flex-basis: 90%;
    }
    .content-summary .MuiGrid-spacing-xs-3>.MuiGrid-item {
        padding-bottom: 0;
        padding-top: 0;
    }

    .only-table-no-sidebar {
        width: 95% !important;
        max-width: calc(100vw - 10px) !important;
    }
}

@media screen and (max-width: 750px) {
    .pb-content--pay {
        padding-bottom: 50px;
    }
    .content-wrapper .MuiGrid-grid-xs-6 {
        max-width: 100%;
        flex-basis: 100%;
    }
    .content-summary {
        position: relative;
        border-bottom: 1px solid #70BF4D;
        padding-bottom: 45px;
    }
    .content-summary .MuiGrid-grid-sm-2 {
        max-width: 90%;
        flex-basis: 90%;
        margin-left: 18px;
        padding-bottom: 18px;
    }
    .content-summary .list-inline.align-right {
        text-align: left;
    }
    .content-summary .MuiGrid-spacing-xs-3 {
        width: calc(100% + 10px);
    }
}

@media screen and (max-width: 560px) {
    .simple-table__responsive table, .simple-table__responsive thead, .simple-table__responsive tbody, .simple-table__responsive th, .simple-table__responsive td, .simple-table__responsive tr {
        display: block;
    }
    .simple-table__responsive thead {
        display: none;
    }
    .simple-table__responsive tr {
        border-bottom: 2px solid #ccc;
    }
    .simple-table .MuiTableCell-root {
        padding: 6px 13px;
    }
    .simple-table__responsive td {
        border: none;
        position: relative;
        padding-top: 10px !important;
        padding-bottom: 0px !important;
    }
    .simple-table__responsive td:before {
        top: 11px;
        left: 6px;
        padding-right: 10px;
        white-space: nowrap;
        font-size: 12px;
        font-weight: bold;
    }
    .simple-table__responsive td:nth-of-type(1):before {
        content: "Unidad:";
    }
    .simple-table__responsive td:nth-of-type(2):before {
        content: "Monto";
    }
    .simple-table__responsive .MuiTableCell-alignRight {
        text-align: left;
    }
    .simple-table__responsive td:nth-of-type(2) {
        padding-top: 0 !important;
        padding-bottom: 1em !important;
    }
    .list-inline__value-witdh .MuiListItem-root {
        width: 48%;
        padding: 0;
    }
    .list-inline__value-witdh .MuiListItem-root:last-child {
        width: 48%;
    }
}

.filter-subheader {
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.875rem;
    box-sizing: border-box;
    list-style: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 48px;
}

.detail-result {
    line-height: 15px;
    margin: 0;
}

.text-xsm {
    font-size: 10px;
    color: grey;
}

.space-between {
    justify-content: space-between;
}

.finesPanel-stepper {
    max-width: 833px;
    padding-left: 3.5%;
    margin-bottom: 0;
}

@media screen and (max-width: 414px) {
    .finesPanel-stepper {
        max-width: 833px;
        padding-left: 3.5%;
        margin-bottom: 1em;
    }
}

.finesPanel-container {
    grid-row: 2/3;
    height: auto;
}

@media screen and (max-width: 414px) {
    .finesPanel-container {
        grid-row: 2/3;
        height: auto;
    }
}